<template>
  <div v-if="currentIcon">
    <Icon
      :iconName="currentIcon.iconName"
      :size="size"
      :color="currentIcon.color"
    /> <br />
    <small v-if="containsDescription">{{ currentIcon.description }}</small>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import { iconList } from '@/assets/icons/iconList'

export default {
  name: 'StatusIcon',
  components: {
    Icon
  },
  props: {
    status: Object,
    containerStatus: String,
    size: String,
    containsDescription: Boolean
  },
  computed: {
    currentIcon: function () {
      if (
        this.status?.fall === true ||
        this.containerStatus === 'fallDetected'
      ) {
        return {
          iconName: iconList.triangleExclaim.icon,
          color: 'red',
          description: this.$t('status.fall')
        }
      }
      if (
        this.status?.heart === false ||
        this.containerStatus === 'noHeartbeat'
      ) {
        return {
          iconName: iconList.circleExclaim.icon,
          color: 'orange',
          description: this.$t('status.offline')
        }
      }
      if (
        this.status?.presence === false ||
        this.containerStatus === 'noPresence'
      ) {
        return {
          iconName: iconList.noPresence.icon,
          color: 'grey',
          description: this.$t('status.noPresence')
        }
      }
      return {
        iconName: iconList.checkCircle.icon,
        color: 'green',
        description: ''
      }
    }
  }
}
</script>
